import React, { useEffect, useRef} from 'react'

export default function Paypal({total, showApproved, showError}){
  const paypal = useRef();


  useEffect(()=>{
    if (window.paypalBtn) window.paypalBtn.close();
    window.paypalBtn = window.paypal_sdk.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: {
                  value: `${total}`,
                  currency_code: "USD"
                }
              }
            ]
          })
        },
        onApprove: async (data, actions) => {
          let order = await actions.order.capture();
          showApproved()
        },
        onError: (err) =>{
          showError();
        }
      });
      window.paypalBtn.render(paypal.current)
  }, [total])

  return(
    <div ref={paypal}></div>
  )
}