import React, {useCallback, useState}  from 'react'
import Logo from './images/Baron_logo.png'
import Paypal from './components/Paypal/PayPal'
import './App.css';

function App(){
  const [total, setTotal] = useState(0.00);
  const [checkout, setCheckout] = useState(false);
  const [purchasedApproved, setPurchaseApproved] = useState(false);
  const [error, setError] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState(false);
  const showApproved = useCallback(() =>{
    setPurchaseApproved(true);
  },[]);

  const showError = useCallback(() =>{
    setError(true);
  },[]);

  const handleChange = (value) =>{
    if(value > 0){
      setErrorMessage(false);
      setTotal(value)
    }
  }

  const handleSubmit = () =>{
    if(total > 0){
      setCheckout(true);
    }else{
      setErrorMessage(true)
    }
  }


  return(
    <div className="App">
      <img className="App-Logo" src={Logo} alt="Baron Championship Rings"/>
      {
        purchasedApproved === false ? (
          <div className="paypal">
          <form>
            <label className="white left">PLEASE ENTER PAYMENT AMOUNT</label>
            {
              showErrorMessage === true ? (
                <p className="red">AMOUNT MUST BE GREATER THAN $0</p>
              ): (
                null
              )
            }
            <input type="text" placeholder="$19.95" onChange={(e)=> handleChange(e.target.value)}/>
          </form>
          {
            checkout === true ? (
              <Paypal total={total} checkout={checkout} showApproved={showApproved} showError={showError}/>
            ):(
              <button className="button" onClick={()=> handleSubmit()}>PAY NOW</button>
            )
          }
      </div>
        ):(
          <div>
            {
              error === false ? (
                <h1 className="white">Thank you. Your purchase has been completed</h1>
              ): (
                <h1 className="white">Sorry, there was a problem processing your payment. Please try again</h1>
              )
            }
           
          </div>
        )
      }

  </div>
  )
}

export default App;